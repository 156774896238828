import React, { Suspense } from "react";
import dynamic from "next/dynamic";

// !!! Library is not SSR Safe !!!
// import { IonIcon, addIcons } from 'react-svg-ionicons';
// import bundle from 'react-svg-ionicons/bundles/all'

// addIcons(bundle);

function IonIconIndex() {
  return (
    <section className="section">
      <div className="columns is-multiline">
        {Object.keys([]).map((name) => (
          <div className="column is-2" key={name}>
            <div className="has-text-centered">
              <IonIcon size="large" className={name} name={name} />
              <p>{name}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

const RealIonIcon = dynamic(
  () => {
    return Promise.all([
      import("react-svg-ionicons/bundles/all"),
      import("react-svg-ionicons"),
    ]).then(([bundle, module]) => {
      module.addIcons(bundle.default);
      return { default: module.IonIcon };
    });
  },
  { ssr: false }
);

function IonIcon(props) {
  const fallback = (
    // copypasta of IonIcon's svg container
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="18px"
      height="18px"
      display="inline-block"
      fill="currentColor"
      stroke="currentColor"
    />
  );

  return (
    <Suspense fallback={fallback}>
      <RealIonIcon {...props} />
    </Suspense>
  );
}

export { IonIcon, IonIconIndex };
