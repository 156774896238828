import useSWR from "swr";

import { validCountries } from "@/src/components/form-fields/CountrySelect";
import { validInterestValues } from "@/src/components/form-fields/common";

type FullUrl = `http://${string}` | `https://${string}`;

type CountryCode = typeof validCountries[number];
type Region = string;

type Interests = typeof validInterestValues[number];
type Seeking = string;

type BaseUser = {
  id: 2;
  username: string;
  email: string;
  is_organization_user: boolean;
};

type Member = BaseUser & {
  is_organization_user: false;
  profile: {
    avatar: FullUrl;
    fullname: string;
    country: CountryCode;
    region: Region;
    city: string;
    phone_number: string;
    intro: string;
    biography: string;
    related_interests: Array<Interests>;
    other_interests: string;
    skills: string;
  };
};
type OrganizationMember = BaseUser & {
  is_organization_user: true;
  organization_profile: {
    name: string;
    avatar: FullUrl;
    address: string;
    website_link: FullUrl;
    contact_email: string;
    phone_number: string;
    intro: string;
    biography: string;
    contact_link: FullUrl | "";
    donate_link: FullUrl | "";
    recruiting_link: FullUrl | "";
    video_link: FullUrl | null;
    seeking: Array<Seeking>;
    get_involved_page_message: string;
    show_projects_on_get_involved_page: boolean;
    marked_for_admin_review: boolean;
    country: CountryCode;
    region: Region;
    city: string;
    organization_type: 1;
    organization_subtype: 6;
    other_areas_of_focus: string;
  };
};

type User = Member | OrganizationMember;

export default function useUser() {
  const { data, error, mutate } = useSWR("/api/users/me/");

  const user = data?.id ? (data as User) : null;

  return {
    user,
    isLoading: !data && !error,
    refreshUser: mutate,
    error,
  };
}

export const USER_CACHE_KEY = "/api/users/me/";
