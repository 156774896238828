import ExternalLink from './ExternalLink';
import { IonIcon } from './Icons';

const socialIcons = {
  'Twitter': { logo: 'logo-twitter', colour: '#1da1f2', link: 'https://twitter.com/@thrivespring' },
  'Youtube': { logo: 'logo-youtube', colour: 'red', link: 'https://www.youtube.com/channel/UCS_5lLZVT4YB97foIJsypmw' },
  'Instagram': { logo: 'logo-instagram', colour: '#f00075', link: 'https://www.instagram.com/thrivespring_/' },
  'LinkedIn': { logo: 'logo-linkedin', colour: 'rgb(0, 120, 188)', link: 'https://www.linkedin.com/company/thrivespring/' },
  'Facebook': { logo: 'logo-facebook', colour: '#1778f2', link: 'https://www.facebook.com/thrivespring/' },
};;

const SocialIcon = ({ name, link }) => (
  <ExternalLink url={link}>
    <div className="icon is-large" style={{color: socialIcons[name].colour}}>
      <IonIcon name={socialIcons[name].logo} size="large" />
    </div>
  </ExternalLink>
);

export default SocialIcon;
