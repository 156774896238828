// Token isn't necessary for the webapp, since Django sends CSRF tokens
// The CSRF is necessary because Django is set up to expect it for POST actions
const retrieveCSRFToken = () => {
  let token = document.cookie.split(";")
    .map(v => v.trim())
    .find(v => v.startsWith("csrf"));

  token = token && token.slice(10);

  return token;
}


export {
  retrieveCSRFToken
};
