import "@/src/thrivespring.css";
import "@/src/components/form-fields/UserFormatableText.css";
import "react-toastify/dist/ReactToastify.css";
import favicon from "@/public/static/favicon.ico";
import emergency from "@/public/static/emergency.jpg";

import { GoogleAnalytics } from "nextjs-google-analytics";
import { SWRConfig } from "swr";
import { Slide, ToastContainer } from "react-toastify";

import { Footer, Navbar } from "@/src/components/Navbar";
import CookieNotice from "@/components/CookieNotice";
import SEOTags from "@/components/SEOTags";
import { useRouter } from "next/router";
import buildAbsoluteURL from "@/components/buildAbsoluteURL";

function fetcher(url, ...args) {
  // only used in client side anyway, so no need for prefixing API hostname
  return fetch(url, ...args).then((res) => res.json());
}

export default function MyApp({ Component, pageProps }) {
  const router = useRouter();

  return (
    <SWRConfig
      value={{
        fetcher,
      }}
    >
      <SEOTags
        absolutePageURL={buildAbsoluteURL(router.asPath)}
        description="Community resilience, self-sustainability + disaster risk management platform"
        imageLink={buildAbsoluteURL(emergency.src)}
        title="Thrivespring"
      >
        <link rel="icon" href={favicon.src} />
        <meta name="theme-color" content="#7FB050" />
      </SEOTags>
      {/* Google Analytics measurement id is read from NEXT_PUBLIC_GA_MEASUREMENT_ID environment variable */}
      <GoogleAnalytics trackPageViews />
      <ToastContainer closeOnClick autoClose={3000} transition={Slide} />
      <Navbar />
      <Component {...pageProps} />
      <Footer />
      <CookieNotice />
    </SWRConfig>
  );
}
