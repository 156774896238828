import Head from "next/head";
import React from "react";

type Props = {
  absolutePageURL: null | string;
  children: React.ReactNode;
  description: string;
  imageLink: string;
  title: string;
};

export default function SEOTags({
  absolutePageURL = null,
  children,
  description,
  imageLink,
  title,
}: Props) {
  const ogTitle =
    title !== "Thrivespring" ? `${title.slice(0, 40)} - Thrivespring` : title;
  const twitterTitle = ogTitle;
  const ogDescription = description.slice(0, 250);
  const twitterDescription = description.slice(0, 200);
  return (
    <Head>
      <title key="title">{title}</title>
      <meta key="og:type" property="og:type" content="article" />
      <meta key="twitter:card" property="twitter:card" content="summary" />
      <meta
        key="twitter:site"
        property="twitter:site"
        content="@thrivespring"
      />

      <meta key="og:title" property="og:title" content={ogTitle} />
      {absolutePageURL && (
        <meta key="og:url" property="og:url" content={absolutePageURL} />
      )}
      <meta key="og:image" property="og:image" content={imageLink} />
      <meta
        key="og:description"
        property="og:description"
        content={ogDescription}
      />

      <meta
        key="twitter:card"
        property="twitter:card"
        content="summary_large_image"
      />
      <meta
        key="twitter:title"
        property="twitter:title"
        content={twitterTitle}
      />
      <meta
        key="twitter:description"
        property="twitter:description"
        content={twitterDescription}
      />
      <meta key="twitter:image" property="twitter:image" content={imageLink} />
      {children}
    </Head>
  );
}
