import { useCallback, useEffect, useState } from "react";
import Link from "next/link";

import isBrowser from "@/components/isBrowser";

const dummyLocalStorage = {};

export default function CookieNotice() {
  const cookieConsentKey = "user.cookieConsent";
  const localStorage = isBrowser() ? window.localStorage : dummyLocalStorage;
  const cookieConsentValue = localStorage[cookieConsentKey];

  const [showNotice, setShowNotice] = useState(false);

  const closeNotice = useCallback(() => {
    localStorage[cookieConsentKey] = true;
    setShowNotice(false);
  }, [localStorage]);

  useEffect(() => {
    if (!cookieConsentValue) {
      setShowNotice(true);
    }
  }, [cookieConsentValue]);

  if (!showNotice) return null;

  return (
    <div
      className="notification is-dark level"
      style={{
        borderRadius: 0,
        bottom: 0,
        position: "fixed",
        width: "100%",
        zIndex: 100,
      }}
    >
      <div className="level-left">
        <p className="level-item">
          We use cookies to provide and improve our services. By using our site,
          you consent to cookies.
        </p>
      </div>

      <div className="level-right">
        <div className="level-item">
          <Link href="/cookie-policy" className="button is-info is-outlined">
            Learn More
          </Link>
        </div>

        <div className="level-item">
          <button className="button is-warning" onClick={closeNotice}>
            Agree
          </button>
        </div>
      </div>
    </div>
  );
}
