import { useCallback, useEffect, useRef, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import useUser from "@/components/useUser";
import { retrieveCSRFToken } from "@/src/utils/ajax";
import ExternalLink from "./ExternalLink";
import { IonIcon } from "./Icons";
import SocialIcon from "./SocialIcon";

const Navbar = () => {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  const router = useRouter();
  const { user, refreshUser } = useUser();

  const toggleMenu = () => setOpen(!isOpen);

  const onClickAway = ({ target }) => {
    if (ref.current && !ref.current.contains(target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", onClickAway, true);
    return () => {
      document.removeEventListener("click", onClickAway, true);
    };
  }, []);

  useEffect(() => {
    if (!user) return;

    (async () => {
      const response = await fetch("/api/inbox/count/");

      if (!response.ok) return;

      const body = await response.json();
      setUnreadCount(body.unread);
    })();
  }, [user, setUnreadCount]);

  const logout = useCallback(async () => {
    const response = await fetch("/api-auth/logout/", {
      method: "POST",
      headers: {
        "X-CSRFToken": retrieveCSRFToken(),
      },
    });

    if (response.status === 200) {
      refreshUser();
    }
  }, [user]);

  const userSessionButtons = () => {
    if (user?.username) {
      return (
        <div className="buttons">
          <Link href="/profile" className="button is-warning">
            <strong>My Profile</strong>
          </Link>
          <button className="button is-light" onClick={logout}>
            Log out
          </button>
        </div>
      );
    }

    return (
      <div className="buttons">
        <Link href="/signup" className="button is-warning">
          <strong>Sign up</strong>
        </Link>
        <Link href={`/login?next=${router.asPath}`} className="button is-light">
          Log in
        </Link>
      </div>
    );
  };

  return (
    <nav
      className="navbar is-primary"
      role="navigation"
      aria-label="main navigation"
      style={{ _backgroundColor: "#94b67b" }}
    >
      <div className="navbar-brand">
        <Link href="/" className="navbar-item is-primary py-0">
          <img
            alt="thrivespring logo"
            src={"/static/thrivespring-logo.png"}
            style={{ maxHeight: "3rem" }}
          />
        </Link>

        <div
          role="button"
          ref={ref}
          onClick={toggleMenu}
          className={`navbar-burger ${isOpen ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarMain"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>

      <div
        id="navbarMain"
        className={`navbar-menu ${isOpen ? "is-active" : ""}`}
      >
        <div className="navbar-start">
          <Link href="/community-projects" className="navbar-item">
            Community Projects
          </Link>

          <Link href="/organizations" className="navbar-item">
            Organizations
          </Link>

          <Link href="/members" className="navbar-item">
            Members
          </Link>

          <Link href="/resources" className="navbar-item">
            Resources
          </Link>

          <Link href="/csr-impact-hub" className="navbar-item">
            CSR Impact Hub
          </Link>

          <div className="navbar-item has-dropdown is-hoverable">
            <span className="navbar-link">More</span>

            <div className="navbar-dropdown">
              <Link href="/about" className="navbar-item">
                About
              </Link>

              <Link href="/purpose-and-guidelines" className="navbar-item">
                Purpose and Guidelines
              </Link>

              <ExternalLink
                className="navbar-item"
                url="https://thrivespring.medium.com/"
                newPage
              >
                Blog
              </ExternalLink>

              <Link href="/your-security" className="navbar-item">
                Your Security
              </Link>

              <Link href="/privacy-policy" className="navbar-item">
                Privacy Policy
              </Link>

              <Link href="/terms-of-service" className="navbar-item">
                Terms of Service
              </Link>

              <Link href="/contact" className="navbar-item">
                Contact Us
              </Link>
            </div>
          </div>
        </div>

        <div className="navbar-end">
          {user && (
            <Link href="/inbox" className="navbar-item">
              <span className="icon">
                <IonIcon name="mail" size="large" />
              </span>
              {unreadCount > 0 && (
                <span
                  className="ml-2 px-2"
                  style={{ backgroundColor: "red", borderRadius: "50%" }}
                >
                  {unreadCount}
                </span>
              )}
            </Link>
          )}
          <div className="navbar-item">{userSessionButtons()}</div>
        </div>
      </div>
    </nav>
  );
};

const SITE_SOCIAL_ICONS = [
  { name: "Twitter", link: "https://twitter.com/@thrivespring" },
  {
    name: "Youtube",
    link: "https://www.youtube.com/channel/UCS_5lLZVT4YB97foIJsypmw",
  },
  { name: "Instagram", link: "https://www.instagram.com/thrivespring_/" },
  { name: "LinkedIn", link: "https://www.linkedin.com/company/thrivespring/" },
  { name: "Facebook", link: "https://www.facebook.com/thrivespring/" },
];

const Footer = () => (
  <footer className="footer">
    <div className="container pb-6">
      <div className="level">
        <div className="level-item">
          <Link href="/about">About</Link>
        </div>

        <div className="level-item">
          <Link href="/purpose-and-guidelines">Purpose and Guidelines</Link>
        </div>

        <div className="level-item">
          <Link href="/your-security">Your Security</Link>
        </div>

        <div className="level-item">
          <Link href="/privacy-policy">Privacy Policy</Link>
        </div>

        <div className="level-item">
          <Link href="/terms-of-service">Terms of Service</Link>
        </div>

        <div className="level-item">
          <Link href="/contact">Contact Us</Link>
        </div>

        <div className="level-item">
          <ExternalLink url="https://www.patreon.com/thrivespring">
            Support Us
          </ExternalLink>
        </div>

        <div className="level-item">
          <Link href="/credits">Credits</Link>
        </div>
      </div>
    </div>

    <div className="container">
      <div>
        <div className="has-text-centered">
          <div>
            <p>{`© ${new Date().getFullYear()} Thrivespring Inc.`}</p>
            <div>
              {SITE_SOCIAL_ICONS.map((i) => (
                <SocialIcon name={i.name} link={i.link} key={i.name} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Navbar;

export { Footer, Navbar };
